import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useState } from "react";
import { useAppStore } from "../../../appStore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../../apiUtils";
import { toast } from "react-toastify";
import KeyboardIcon from "@mui/icons-material/Keyboard";

const validationSchema = yup.object({
  requestedAmount: yup
    .number()
    .required("Points is required")
    .min(300, "Minimum amount should be 300"),
  paymentMode: yup.string().required("PaymentMode is required"),
  customPaymentMode: yup.string(),
});

function AddPoints() {
  const { setAddPointOpen, depositSent, setDepositSent } = useAppStore();
  const navigate = useNavigate();
  const [files, setFiles] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isOtherPayment, setIsOtherPayment] = useState(false); // State to track if "Other" is selected
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setsnackbarMessage = useAppStore((state) => state.setSnackbarMessage);
  const setopenSnackbar = useAppStore((state) => state.setOpenSnackbar);

  const handleFileChange = (event) => {
    const inputFiles = event.target.files;
    setFiles(inputFiles);

    // Create image preview URLs
    const filePreviews = Array.from(inputFiles).map((file) =>
      URL.createObjectURL(file)
    );
    setImagePreview(filePreviews);
  };

  const formik = useFormik({
    initialValues: {
      requestedAmount: "300",
      paymentMode: "",
      customPaymentMode: "", // Initial value for custom payment mode
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (isSubmitting) return; // Prevent multiple clicks

      setIsSubmitting(true); // Disable button after first click

      const formData = new FormData();
      formData.append("requestedAmount", values.requestedAmount);
      formData.append(
        "paymentMode",
        isOtherPayment ? values.customPaymentMode : values.paymentMode // Send custom payment mode if "Other" is selected
      );

      // Append files to formData
      if (files) {
        Array.from(files).forEach((file) => {
          formData.append("image", file);
        });
      }

      try {
        const token = sessionStorage.getItem("accesstoken");
        const response = await axios.post(
          `${api_url}wallet/deposit`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              accesstoken: token,
            },
          }
        );

        const data = response.data;
        if (data.status === "success") {
          setDepositSent(true);
          toast.success("Request processing..");
          navigate("/wallet/manage-points");
          setAddPointOpen(false);
        }
      } catch (error) {
        toast.error("An error occurred while sending the request");
      }

      setIsSubmitting(false); // Re-enable button after request completes
      // Reset the form fields after successful submission
      resetForm();
    },
  });

  const handlePaymentModeChange = (event) => {
    const inputPaymentMode = event.target.value;
    formik.setFieldValue("paymentMode", inputPaymentMode);
    formik.setFieldTouched("paymentMode", true);

    // Check if "Other" is selected and update state
    if (inputPaymentMode === "other") {
      setIsOtherPayment(true);
    } else {
      setIsOtherPayment(false);
    }
  };

  const handleRequestedAmountChange = (event) => {
    const inputRequestedAmount = event.target.value;
    formik.setFieldValue("requestedAmount", inputRequestedAmount);
  };

  const handleCustomPaymentModeChange = (event) => {
    const inputCustomPaymentMode = event.target.value;
    formik.setFieldValue("customPaymentMode", inputCustomPaymentMode);
  };

  return (
    <>
      <Box sx={{ textAlign: "center" }} mb={1}>
        <Typography variant="h5">Add Points To Wallet</Typography>
        <Typography variant="caption" sx={{ color: "#bdbdbd" }}>
          Send request to deposit points in wallet
        </Typography>
      </Box>
      <Box sx={{ border: "1px dashed #bdbdbd" }} />

      <Box height={10} />
      <Grid container mt={3}>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Deposit Point :</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            id="requestedAmount"
            name="requestedAmount"
            error={
              formik.touched.requestedAmount && formik.errors.requestedAmount
            }
            helperText={
              formik.touched.requestedAmount && formik.errors.requestedAmount
            }
            value={formik.values.requestedAmount}
            onChange={handleRequestedAmountChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
      </Grid>
      <Grid container mt={3}>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Payment Mode :</Typography>
        </Grid>
        <Grid item xs={6}>
          <Select
            fullWidth
            size="small"
            id="paymentMode"
            name="paymentMode"
            error={formik.touched.paymentMode && formik.errors.paymentMode}
            helperText={formik.touched.paymentMode && formik.errors.paymentMode}
            value={formik.values.paymentMode}
            onChange={handlePaymentModeChange}
            onBlur={formik.handleBlur}
          >
            <MenuItem value={"ppe"}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box pt={0.5}>
                  <img src="../images/p-pay1.png" alt="PhonePe" width="30px" />
                </Box>
                <Box>
                  <span>PhonePe</span>
                </Box>
              </Box>
            </MenuItem>
            <MenuItem value={"gpay"}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box pt={0.5}>
                  <img src="../images/g-pay1.png" alt="G-Pay" width="30px" />
                </Box>
                <Box>
                  <span>G-Pay</span>
                </Box>
              </Box>
            </MenuItem>
            <MenuItem value={"paytm"}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box pt={0.5}>
                  <img src="../images/paytm.png" alt="Paytm" width="30px" />
                </Box>
                <Box>
                  <span>Paytm</span>
                </Box>
              </Box>
            </MenuItem>
            <MenuItem value={"other"}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box pt={0.5}>
                  <KeyboardIcon sx={{ width: "30px" }} />
                </Box>
                <Box>
                  <span>Other</span>
                </Box>
              </Box>
            </MenuItem>
          </Select>
        </Grid>
      </Grid>

      {/* Conditionally render the custom payment mode input field */}
      {isOtherPayment && (
        <Grid container mt={3}>
          <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography>Enter Payment Mode :</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              id="customPaymentMode"
              name="customPaymentMode"
              value={formik.values.customPaymentMode}
              onChange={handleCustomPaymentModeChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
        </Grid>
      )}

      <Grid container item xs={12} mt={5}>
        {imagePreview ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Box sx={{ background: "#000", width: "400px", height: "200px" }}>
              {imagePreview.map((img, index) => (
                <img
                  width={"100%"}
                  height={"100%"}
                  key={index}
                  src={img}
                  alt={`Uploaded ${index}`}
                />
              ))}
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              component="label"
              sx={{
                width: "100%",
                border: "2px dotted #212762",
                borderRadius: "4px",
                padding: "8px",
                height: "150px",
              }}
            >
              <AddCircleIcon sx={{ fontSize: "50px", color: "#212762" }} />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </Button>
          </Grid>
        )}
      </Grid>

      <Box sx={{ border: "1px dashed #e0e0e0", mt: 2 }} />
      <Box height={20} />

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box>
          <Button
            fullWidth
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#1e2466",
              height: "100%",
              letterSpacing: 0.5,
            }}
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={isSubmitting} // Disable button when submitting
          >
            {isSubmitting ? "Verifying..." : "Verify & Add Points"}
          </Button>
        </Box>
        <Box mt={1}>
          <Button
            fullWidth
            variant="outlined"
            color="error"
            onClick={() => setAddPointOpen(false)}
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default AddPoints;
